/**
 * Component - longInfoBlocks
 */

export const longInfoBlocks = (): void => {
  // Run the script for each individual component found on the page
  const componentSelector = document.querySelectorAll('.js-longInfoBlocks') as unknown as HTMLElement[]
  componentSelector.forEach((component: HTMLElement) => {
    console.log(component)
  })
}
