/**
 * Component - topSlider
 */

export const topSlider = (): void => {
  // Run the script for each individual component found on the page
  const componentSelector = document.querySelectorAll('.js-topSlider') as unknown as HTMLElement[]
  componentSelector.forEach((component: HTMLElement) => {
    sliderControl(component)
  })
}

const sliderControl = (slider: HTMLElement): void => {
  /* Selectors */
  const slideList = slider.querySelectorAll('.js-topSlider-singleSlide')
  const dotList = slider.querySelectorAll('.js-topSlider-dot') as unknown as HTMLElement[]
  const controlPrev = slider.querySelector('.js-topSlider-sliderControl-prev')
  const controlNext = slider.querySelector('.js-topSlider-sliderControl-next')

  /* Kill script if used as a banner and not as a slider */
  if (slideList.length <= 1) {
    return
  }

  /* Local Variables */
  let autoSlideTimer = 0
  let currentSlideId = 0
  let transitionLock = false
  const touchCoordsStart = {
    x: 0,
    y: 0
  }
  const touchCoordsEnd = {
    x: 0,
    y: 0
  }

  /* Control logic */
  const touchHandling = () => {
    const differenceX = touchCoordsStart.x - touchCoordsEnd.x
    const absoluteDifferenceX = Math.abs(differenceX)
    const limiter = 100

    if (absoluteDifferenceX > limiter) {
      if (differenceX < 0) {
        moveToPrevSlide()
      }
      else {
        moveToNextSlide()
      }
    }
  }

  const moveToPrevSlide = () => {
    const prevTheoreticalID = currentSlideId - 1
    const prevSlideID = (prevTheoreticalID < 0) ? slideList.length - 1 : prevTheoreticalID
    changeSlide(prevSlideID)
  }

  const moveToNextSlide = () => {
    const nextTheoreticalID = currentSlideId + 1
    const nextSlideID = (nextTheoreticalID > slideList.length - 1) ? 0 : nextTheoreticalID
    changeSlide(nextSlideID)
  }

  const changeSlide = (followingSlideID: number) => {
    if (transitionLock) return

    /* Lock transition */
    transitionLock = true
    autoSlideTimer = 0

    /* Local variables */
    const localSnapshotID = currentSlideId

    /* Change dots */
    dotList[localSnapshotID].classList.remove('-active')
    dotList[followingSlideID].classList.add('-active')

    /* Change slide */
    slideList[localSnapshotID].classList.add('-phaseOut')
    slideList[localSnapshotID].classList.remove('-active')

    slideList[followingSlideID].classList.add('-phaseIn')
    slideList[followingSlideID].classList.add('-active')

    setTimeout(() => {
      /* Cleanup */
      slideList[localSnapshotID].classList.remove('-phaseOut')
      slideList[followingSlideID].classList.remove('-phaseIn')

      /* Unlock transition */
      transitionLock = false

      /* Change local ID */
      currentSlideId = followingSlideID
    }, 750)
  }

  /* UI Interactions */
  if (controlPrev) {
    controlPrev.addEventListener('click', () => {
      moveToPrevSlide()
    })
  }

  if (controlNext) {
    controlNext.addEventListener('click', () => {
      moveToNextSlide()
    })
  }

  dotList.forEach((dot: HTMLElement, index: number) => {
    if (dot && !dot.classList.contains('active')) {
      dot.addEventListener('click', () => {
        changeSlide(index)
      })
    }
  })

  slider.addEventListener('touchstart', (event) => {
    touchCoordsStart.x = event.changedTouches[0].clientX
    touchCoordsStart.y = event.changedTouches[0].clientY
  })

  slider.addEventListener('touchend', (event) => {
    touchCoordsEnd.x = event.changedTouches[0].clientX
    touchCoordsEnd.y = event.changedTouches[0].clientY
    touchHandling()
  })

  /* Autoplay 10 seconds */
  setInterval(() => {
    autoSlideTimer = autoSlideTimer + 25

    if (autoSlideTimer >= 10000) {
      moveToNextSlide()
    }
  }, 25)
}
