/**
 * Component - contactForm
 */

export const contactForm = (): void => {
  // Run the script for each individual component found on the page
  const componentSelector = document.querySelectorAll('.js-contactForm') as unknown as HTMLElement[]
  componentSelector.forEach((component: HTMLElement) => {
    const inputWrappers = component.querySelectorAll('.js-contactForm-inputWrapper') as unknown as HTMLElement[]

    inputWrappers.forEach((inputWrapper: HTMLElement) => {
      if (inputWrapper.classList.contains('js-contactForm-dateWrapper')) {
        engageDatepicker(inputWrapper)
      }

      const localInputsSelector = inputWrapper.querySelectorAll('input, textarea')

      if (localInputsSelector.length > 0) {
        const localInput = localInputsSelector[0] as unknown as HTMLInputElement

        localInput.addEventListener('focus', () => {
          inputWrapper.classList.add('-active')
          localInput.value = localInput.value.trim()
        })

        localInput.addEventListener('blur', () => {
          setTimeout(() => {
            if (localInput.value.trim().length === 0) {
              inputWrapper.classList.remove('-active')
            }
          }, 200)
        })
      }
    })
  })
}

/**
 * Endgages a single datepicker
 * @param datepicker The current datepicker element
 */
export const engageDatepicker = (datepickerElement: HTMLElement): void => {
  const localInput = datepickerElement.querySelector('input') as unknown as HTMLInputElement

  // @ts-ignore
  if (window.Datepicker !== undefined) {
    const dateInput = new Date()
    dateInput.setDate(dateInput.getDate() + 7)

    // @ts-ignore
    new window.Datepicker(localInput, {
      autohide: true,
      language: 'cs',
      format: 'd. m. yyyy',
      minDate: dateInput,
      orientation: 'auto right',
      // daysOfWeekDisabled: [6, 0],
      datesDisabled: [
        /*   // 2022
          '1. 1. 2022',
          '15. 4. 2022', // Velikonoce - Patek
          '18. 4. 2022', // Velikonoce - Pondeli
          '1. 5. 2022',
          '8. 5. 2022',
          '5. 7. 2022',
          '6. 7. 2022',
          '28. 9. 2022',
          '28. 10. 2022',
          '17. 11. 2022',
          '24. 12. 2022',
          '25. 12. 2022',
          '26. 12. 2022',

          // 2023
          '1. 1. 2023',
          '7. 4. 2023', // Velikonoce - Patek
          '10. 4. 2023', // Velikonoce - Pondeli
          '1. 5. 2023',
          '8. 5. 2023',
          '5. 7. 2023',
          '6. 7. 2023',
          '28. 9. 2023',
          '28. 10. 2023',
          '17. 11. 2023',
          '24. 12. 2023',
          '25. 12. 2023',
          '26. 12. 2023',

          // 2024
          '1. 1. 2024',
          '29. 3. 2024', // Velikonoce - Patek
          '1 . 4. 2024', // Velikonoce - Pondeli
          '1. 5. 2024',
          '8. 5. 2024',
          '5. 7. 2024',
          '6. 7. 2024',
          '28. 9. 2024',
          '28. 10. 2024',
          '17. 11. 2024',
          '24. 12. 2024',
          '25. 12. 2024',
          '26. 12. 2024',

          // 2025
          '1. 1. 2025',
          '18. 4. 2025', // Velikonoce - Patek
          '21. 4. 2025', // Velikonoce - Pondeli
          '1. 5. 2025',
          '8. 5. 2025',
          '5. 7. 2025',
          '6. 7. 2025',
          '28. 9. 2025',
          '28. 10. 2025',
          '17. 11. 2025',
          '24. 12. 2025',
          '25. 12. 2025',
          '26. 12. 2025',

          // 2026
          '1. 1. 2026',
          '3. 4. 2026', // Velikonoce - Patek
          '6. 4. 2026', // Velikonoce - Pondeli
          '1. 5. 2026',
          '8. 5. 2026',
          '5. 7. 2026',
          '6. 7. 2026',
          '28. 9. 2026',
          '28. 10. 2026',
          '17. 11. 2026',
          '24. 12. 2026',
          '25. 12. 2026',
          '26. 12. 2026',

          // 2027
          '1. 1. 2027',
          '26. 3. 2027', // Velikonoce - Patek
          '29. 3. 2027', // Velikonoce - Pondeli
          '1. 5. 2027',
          '8. 5. 2027',
          '5. 7. 2027',
          '6. 7. 2027',
          '28. 9. 2027',
          '28. 10. 2027',
          '17. 11. 2027',
          '24. 12. 2027',
          '25. 12. 2027',
          '26. 12. 2027',

          // 2028
          '1. 1. 2028',
          '14. 4. 2028', // Velikonoce - Patek
          '17. 4. 2028', // Velikonoce - Pondeli
          '1. 5. 2028',
          '8. 5. 2028',
          '5. 7. 2028',
          '6. 7. 2028',
          '28. 9. 2028',
          '28. 10. 2028',
          '17. 11. 2028',
          '24. 12. 2028',
          '25. 12. 2028',
          '26. 12. 2028',

          // 2029
          '1. 1. 2029',
          '30. 3. 2029', // Velikonoce - Patek
          '2. 4. 2029', // Velikonoce - Pondeli
          '1. 5. 2029',
          '8. 5. 2029',
          '5. 7. 2029',
          '6. 7. 2029',
          '28. 9. 2029',
          '28. 10. 2029',
          '17. 11. 2029',
          '24. 12. 2029',
          '25. 12. 2029',
          '26. 12. 2029',

          // 2030
          '1. 1. 2030',
          '19. 4. 2030', // Velikonoce - Patek
          '22. 4. 2030', // Velikonoce - Pondeli
          '1. 5. 2030',
          '8. 5. 2030',
          '5. 7. 2030',
          '6. 7. 2030',
          '28. 9. 2030',
          '28. 10. 2030',
          '17. 11. 2030',
          '24. 12. 2030',
          '25. 12. 2030',
          '26. 12. 2030',

          // 2031
          '1. 1. 2031',
          '11. 4. 2031', // Velikonoce - Patek
          '14. 4. 2031', // Velikonoce - Pondeli
          '1. 5. 2031',
          '8. 5. 2031',
          '5. 7. 2031',
          '6. 7. 2031',
          '28. 9. 2031',
          '28. 10. 2031',
          '17. 11. 2031',
          '24. 12. 2031',
          '25. 12. 2031',
          '26. 12. 2031',

          // 2032
          '1. 1. 2032',
          '26. 3. 2032', // Velikonoce - Patek
          '29. 3. 2032', // Velikonoce - Pondeli
          '1. 5. 2032',
          '8. 5. 2032',
          '5. 7. 2032',
          '6. 7. 2032',
          '28. 9. 2032',
          '28. 10. 2032',
          '17. 11. 2032',
          '24. 12. 2032',
          '25. 12. 2032',
          '26. 12. 2032' */
      ]
    })
  }
}
