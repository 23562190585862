/**
  * Sub-component - foodList_tabs
  * Parent Component - foodList
 */

export const foodList_tabsElements = (parentElement: HTMLElement) => {
  return parentElement.querySelectorAll('.js-foodList_tabs__tab') as unknown as HTMLElement[]
}

export const foodList_tabs = (tabList: HTMLElement[], itemLists: HTMLElement[]): void => {
  // Kill the script if the selector doesn't exist
  if (tabList === null || itemLists === null) return

  tabList.forEach(tab => {
    tab.addEventListener('click', () => {
      manageSingleTab(tab, tabList, itemLists)
    })
  })
}

const manageSingleTab = (tab: HTMLElement, tabList: HTMLElement[], itemLists: HTMLElement[]) => {
  const activeTab = tab
  const activeTabID = tab.dataset.tabTriggerId

  if (!activeTabID) return

  const activeList = [...itemLists].find((list) => {
    return list.dataset.itemListId === activeTabID
  })

  if (!activeList) return

  tabList.forEach(tab => {
    tab.classList.remove('-active')
  })
  activeTab.classList.add('-active')

  itemLists.forEach(list => {
    list.classList.remove('-active')
  })
  activeList.classList.add('-active')
}
