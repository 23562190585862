/**
 * Component - contactMap
 */

// @ts-nocheck

/* SETUP: Cumpelikova */
const loadCumpelikova = (map, mapIcon, component, allTabs, allContents) => {
  let adressCumpelikova = {
    lat: 50.126713,
    lng: 14.461399
  }

  let markerCumpelikova = new google.maps.Marker({
    map,
    position: adressCumpelikova,
    title: 'Pizza caffe U veverky - Čumpelíkova',
    icon: mapIcon
  })

  let infoCumpelikova = new google.maps.InfoWindow({
    content: '<div class="mapcontainer"><div class="mappicture"> </div><div class="mapcontent"> <h2>Pizza & cafe U veverky <br>Čumpelíkova <br>739 225 017</h2> <div>Čumpelíkova 1764/2<br>182 00, Praha 8 Kobylisy<br></div><br></div><br class="clr"></div>'
  })

  markerCumpelikova.addListener('click', function () {
    infoCumpelikova.open(map, markerCumpelikova)
  })

  let pathCumpelikova = [
    new google.maps.LatLng(50.1529262, 14.4432878),
    new google.maps.LatLng(50.1499289, 14.4413996),
    new google.maps.LatLng(50.1445937, 14.4426012),
    new google.maps.LatLng(50.1355171, 14.4449615),
    new google.maps.LatLng(50.1318859, 14.4480944),
    new google.maps.LatLng(50.1292174, 14.4404125),
    new google.maps.LatLng(50.1275117, 14.4345760),
    new google.maps.LatLng(50.1236873, 14.4353914),
    new google.maps.LatLng(50.1229169, 14.4363356),
    new google.maps.LatLng(50.1188721, 14.4326878),
    new google.maps.LatLng(50.1146342, 14.4334173),
    new google.maps.LatLng(50.1167532, 14.4448328),
    new google.maps.LatLng(50.1145242, 14.4493389),
    new google.maps.LatLng(50.1154598, 14.4584799),
    new google.maps.LatLng(50.1133958, 14.4625998),
    new google.maps.LatLng(50.1131206, 14.4654322),
    new google.maps.LatLng(50.1123776, 14.4691658),
    new google.maps.LatLng(50.1115519, 14.4716549),
    new google.maps.LatLng(50.1109465, 14.4735861),
    new google.maps.LatLng(50.1125977, 14.4779634),
    new google.maps.LatLng(50.1130931, 14.4831562),
    new google.maps.LatLng(50.1154323, 14.4923830),
    new google.maps.LatLng(50.1155974, 14.4954300),
    new google.maps.LatLng(50.1190647, 14.5008802),
    new google.maps.LatLng(50.1213210, 14.5046568),
    new google.maps.LatLng(50.1256683, 14.5160294),
    new google.maps.LatLng(50.1301528, 14.5210505),
    new google.maps.LatLng(50.1343067, 14.5253420),
    new google.maps.LatLng(50.1444562, 14.5338821),
    new google.maps.LatLng(50.1514963, 14.5396328),
    new google.maps.LatLng(50.1578205, 14.5317793),
    new google.maps.LatLng(50.1546035, 14.5124245),
    new google.maps.LatLng(50.1521013, 14.5064592),
    new google.maps.LatLng(50.1427785, 14.5054293),
    new google.maps.LatLng(50.1384053, 14.5039272),
    new google.maps.LatLng(50.1340316, 14.5060730),
    new google.maps.LatLng(50.1329038, 14.4983482),
    new google.maps.LatLng(50.1393405, 14.4970608),
    new google.maps.LatLng(50.1435761, 14.5008373),
    new google.maps.LatLng(50.1503964, 14.4935417),
    new google.maps.LatLng(50.1527887, 14.4859886),
    new google.maps.LatLng(50.1512763, 14.4826412),
    new google.maps.LatLng(50.1490489, 14.4788218),
    new google.maps.LatLng(50.1497914, 14.4693804),
    new google.maps.LatLng(50.1522113, 14.4582224),
    new google.maps.LatLng(50.1528987, 14.4433737)
  ]

  let polygonCumpelikova = new google.maps.Polygon({
    paths: pathCumpelikova,
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35
  })

  polygonCumpelikova.setMap(map)
}

/* SETUP: Olstynska */
const loadOlstynska = (map, mapIcon, component, allTabs, allContents) => {
  const zoneID = 'yellow'

  const directButton = component.querySelector(`.js-contactMap__instantCTA[data-zone="${zoneID}"]`)

  const infoWrapper = component.querySelector('.js-contactMap__info')

  const tab = component.querySelector(`.js-contactMap__infoTab[data-zone="${zoneID}"]`)
  const infoContent = component.querySelector(`.js-contactMap__infoContent[data-zone="${zoneID}"]`)

  let adressOlstynska = {
    lat: 50.127660,
    lng: 14.419900
  }

  let markerOlstynska = new google.maps.Marker({
    map,
    position: adressOlstynska,
    title: 'Pizza caffe U veverky - Olštýnská',
    icon: mapIcon
  })

  let pathOlstynska = [
    new google.maps.LatLng(50.14096627357049, 14.420372815836572),
    new google.maps.LatLng(50.140086121960785, 14.41642460416665),
    new google.maps.LatLng(50.13964604008334, 14.406468244303369),
    new google.maps.LatLng(50.13706047729382, 14.405781598795556),
    new google.maps.LatLng(50.13414467502613, 14.403120847452783),
    new google.maps.LatLng(50.13243912341258, 14.401661725748681),
    new google.maps.LatLng(50.13051342755838, 14.406639905680322),
    new google.maps.LatLng(50.129137883074584, 14.406639905680322),
    new google.maps.LatLng(50.12627662389992, 14.406897397745752),
    new google.maps.LatLng(50.12225956757931, 14.406983228434228),
    new google.maps.LatLng(50.118847556357906, 14.409901471842431),
    new google.maps.LatLng(50.11637094429059, 14.41393551420083),
    new google.maps.LatLng(50.11675620347093, 14.434105725992822),
    new google.maps.LatLng(50.12088378586261, 14.453417630900049),
    new google.maps.LatLng(50.1228648990085, 14.454275937784814),
    new google.maps.LatLng(50.12677185407507, 14.452816816080713),
    new google.maps.LatLng(50.13337443343818, 14.453589292277002),
    new google.maps.LatLng(50.146521856862236, 14.450284810770654),
    new google.maps.LatLng(50.1470718795077, 14.446422429789209),
    new google.maps.LatLng(50.146576859411454, 14.441615911234521),
    new google.maps.LatLng(50.146686864320046, 14.43363365720620)
  ]

  let polygonOlstynska = new google.maps.Polygon({
    paths: pathOlstynska,
    strokeColor: '#e7c676',
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: '#e7c676',
    fillOpacity: 0.5
  })

  polygonOlstynska.setMap(map)

  const openInfoPannel = () => {
    infoWrapper.classList.add('-active')

    allTabs.forEach((single: HTMLElement) => {
      single.classList.remove('-active')
    })
    allContents.forEach((single: HTMLElement) => {
      single.classList.remove('-active')
    })

    tab.classList.add('-active')
    infoContent.classList.add('-active')

    const windowWidth = window.innerWidth
    const headerHeight = (jQuery('header')) ? jQuery('header').height() : 0
    const scrollTarget = jQuery(infoWrapper)

    if (windowWidth <= 1024) {
      jQuery('html, body').animate({
        scrollTop: scrollTarget.offset().top - headerHeight
      }, 800)
    }
  }

  polygonOlstynska.addListener('click', function () {
    openInfoPannel()
  })

  markerOlstynska.addListener('click', function () {
    openInfoPannel()
  })

  tab.addEventListener('click', function () {
    openInfoPannel()
  })

  directButton.addEventListener('click', function () {
    openInfoPannel()
  })
}

/* SETUP: Waltariho */
const loadWaltariho = (map, mapIcon, component, allTabs, allContents) => {
  let waltariho = {
    lat: 50.1085752,
    lng: 14.5233454
  }

  let markerWaltariho = new google.maps.Marker({
    map,
    position: waltariho,
    title: 'Pizza caffe U veverky - Hloubětín',
    icon: mapIcon
  })

  let infoWaltariho = new google.maps.InfoWindow({
    content: '<div class="mapcontainer"><div class="mappicture"> </div><div class="mapcontent"> <h2>Pizza & cafe U veverky <br>Hloubětín<br>603 261 747</h2> <div>Waltariho 1148/1<br>198 00, Praha 9<br></div><br><br></div><br class="clr"></div>'
  })
  markerWaltariho.addListener('click', function () {
    infoWaltariho.open(map, markerWaltariho)
  })
  let pathHloubetin = [

    new google.maps.LatLng(50.1247054, 14.5131969),
    new google.maps.LatLng(50.1232196, 14.5095921),
    new google.maps.LatLng(50.1227793, 14.5077896),
    new google.maps.LatLng(50.1160652, 14.4944000),
    new google.maps.LatLng(50.1165606, 14.4883919),
    new google.maps.LatLng(50.1169458, 14.4774055),
    new google.maps.LatLng(50.1143315, 14.4747448),
    new google.maps.LatLng(50.1120198, 14.4706678),
    new google.maps.LatLng(50.1107263, 14.4771910),
    new google.maps.LatLng(50.1032948, 14.4840574),
    new google.maps.LatLng(50.1007348, 14.5071459),
    new google.maps.LatLng(50.1046436, 14.5064592),
    new google.maps.LatLng(50.1056620, 14.5201063),
    new google.maps.LatLng(50.1045610, 14.5276594),
    new google.maps.LatLng(50.1005696, 14.5309210),
    new google.maps.LatLng(50.0999365, 14.5358562),
    new google.maps.LatLng(50.1025516, 14.5426798),
    new google.maps.LatLng(50.1026066, 14.5558977),
    new google.maps.LatLng(50.1062675, 14.5564985),
    new google.maps.LatLng(50.1072309, 14.5522499),
    new google.maps.LatLng(50.1126528, 14.5695019),
    new google.maps.LatLng(50.1165055, 14.5673132),
    new google.maps.LatLng(50.1164230, 14.5597601),
    new google.maps.LatLng(50.1103410, 14.5492458),
    new google.maps.LatLng(50.1114143, 14.5336676),
    new google.maps.LatLng(50.1181841, 14.5189476),
    new google.maps.LatLng(50.1246778, 14.5132828)
  ]

  let hloubetin = new google.maps.Polygon({
    paths: pathHloubetin,
    strokeColor: '#FF0FF0',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0FF0',
    fillOpacity: 0.35
  })
  hloubetin.setMap(map)
}

/* SETUP: Cumpelikova & Waltariho Hybrid */
const loadCumpelikovaWaltarihoHybrid = (map, mapIcon, component, allTabs, allContents) => {
  const zoneID = 'red'

  const directButton = component.querySelector(`.js-contactMap__instantCTA[data-zone="${zoneID}"]`)

  const infoWrapper = component.querySelector('.js-contactMap__info')

  const tab = component.querySelector(`.js-contactMap__infoTab[data-zone="${zoneID}"]`)
  const infoContent = component.querySelector(`.js-contactMap__infoContent[data-zone="${zoneID}"]`)

  let adressCumpelikova = {
    lat: 50.126713,
    lng: 14.461399
  }

  let markerCumpelikova = new google.maps.Marker({
    map,
    position: adressCumpelikova,
    title: 'Pizza caffe U veverky - Čumpelíkova',
    icon: mapIcon
  })

  let pathCumpelikova = [
    new google.maps.LatLng(50.1529262, 14.4432878),
    new google.maps.LatLng(50.1499289, 14.4413996),
    new google.maps.LatLng(50.1445937, 14.4426012),
    new google.maps.LatLng(50.1355171, 14.4449615),
    new google.maps.LatLng(50.1318859, 14.4480944),
    new google.maps.LatLng(50.1292174, 14.4404125),
    new google.maps.LatLng(50.1275117, 14.4345760),
    new google.maps.LatLng(50.1236873, 14.4353914),
    new google.maps.LatLng(50.1229169, 14.4363356),
    new google.maps.LatLng(50.1188721, 14.4326878),
    new google.maps.LatLng(50.1146342, 14.4334173),
    new google.maps.LatLng(50.1167532, 14.4448328),
    new google.maps.LatLng(50.1145242, 14.4493389),
    new google.maps.LatLng(50.1154598, 14.4584799),
    new google.maps.LatLng(50.1133958, 14.4625998),
    new google.maps.LatLng(50.1131206, 14.4654322),
    new google.maps.LatLng(50.1107263, 14.4771910),
    new google.maps.LatLng(50.1032948, 14.4840574),
    new google.maps.LatLng(50.1007348, 14.5071459),
    new google.maps.LatLng(50.1046436, 14.5064592),
    new google.maps.LatLng(50.1056620, 14.5201063),
    new google.maps.LatLng(50.1045610, 14.5276594),
    new google.maps.LatLng(50.1005696, 14.5309210),
    new google.maps.LatLng(50.0999365, 14.5358562),
    new google.maps.LatLng(50.1025516, 14.5426798),
    new google.maps.LatLng(50.1026066, 14.5558977),
    new google.maps.LatLng(50.1062675, 14.5564985),
    new google.maps.LatLng(50.1072309, 14.5522499),
    new google.maps.LatLng(50.1126528, 14.5695019),
    new google.maps.LatLng(50.1165055, 14.5673132),
    new google.maps.LatLng(50.1164230, 14.5597601),
    new google.maps.LatLng(50.1103410, 14.5492458),
    new google.maps.LatLng(50.1114143, 14.5336676),
    new google.maps.LatLng(50.1181841, 14.5189476),
    new google.maps.LatLng(50.1246778, 14.5132828),
    new google.maps.LatLng(50.1301528, 14.5210505),
    new google.maps.LatLng(50.1343067, 14.5253420),
    new google.maps.LatLng(50.1444562, 14.5338821),
    new google.maps.LatLng(50.1514963, 14.5396328),
    new google.maps.LatLng(50.1578205, 14.5317793),
    new google.maps.LatLng(50.1546035, 14.5124245),
    new google.maps.LatLng(50.1521013, 14.5064592),
    new google.maps.LatLng(50.1427785, 14.5054293),
    new google.maps.LatLng(50.1384053, 14.5039272),
    new google.maps.LatLng(50.1340316, 14.5060730),
    new google.maps.LatLng(50.1329038, 14.4983482),
    new google.maps.LatLng(50.1393405, 14.4970608),
    new google.maps.LatLng(50.1435761, 14.5008373),
    new google.maps.LatLng(50.1503964, 14.4935417),
    new google.maps.LatLng(50.1527887, 14.4859886),
    new google.maps.LatLng(50.1512763, 14.4826412),
    new google.maps.LatLng(50.1490489, 14.4788218),
    new google.maps.LatLng(50.1497914, 14.4693804),
    new google.maps.LatLng(50.1522113, 14.4582224),
    new google.maps.LatLng(50.1528987, 14.4433737)

  ]

  let polygonCumpelikova = new google.maps.Polygon({
    paths: pathCumpelikova,
    strokeColor: '#941b28',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#941b28',
    fillOpacity: 0.5
  })

  polygonCumpelikova.setMap(map)

  const openInfoPannel = () => {
    infoWrapper.classList.add('-active')

    allTabs.forEach((single: HTMLElement) => {
      single.classList.remove('-active')
    })
    allContents.forEach((single: HTMLElement) => {
      single.classList.remove('-active')
    })

    tab.classList.add('-active')
    infoContent.classList.add('-active')

    const windowWidth = window.innerWidth
    const headerHeight = (jQuery('header')) ? jQuery('header').height() : 0
    const scrollTarget = jQuery(infoWrapper)

    if (windowWidth <= 1024) {
      jQuery('html, body').animate({
        scrollTop: scrollTarget.offset().top - headerHeight
      }, 800)
    }
  }

  polygonCumpelikova.addListener('click', function () {
    openInfoPannel()
  })

  markerCumpelikova.addListener('click', function () {
    openInfoPannel()
  })

  tab.addEventListener('click', function () {
    openInfoPannel()
  })

  directButton.addEventListener('click', function () {
    openInfoPannel()
  })
}

export const contactMap = (): void => { // Run the script for each individual component found on the page
  const componentSelector = document.querySelectorAll('.js-contactMap') as unknown as HTMLElement[]
  componentSelector.forEach((component: HTMLElement) => {
    const mapDiv = component.querySelector('.js-contactMap__inner')

    const mapIcon = component.dataset.icon

    let adressCenter = {
      lat: 50.126713,
      lng: 14.461399
    }

    let map = new google.maps.Map(mapDiv, {
      center: adressCenter,
      scrollwheel: false,
      panControl: false,
      scaleControl: false,
      streetViewControl: false,
      zoom: 13,
      styles: [
        {
          featureType: 'water',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#d3d3d3'
            }
          ]
        },
        {
          featureType: 'transit',
          stylers: [
            {
              color: '#808080'
            },
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [
            {
              visibility: 'on'
            },
            {
              color: '#b3b3b3'
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#ffffff'
            }
          ]
        },
        {
          featureType: 'road.local',
          elementType: 'geometry.fill',
          stylers: [
            {
              visibility: 'on'
            },
            {
              color: '#ffffff'
            },
            {
              weight: 1.8
            }
          ]
        },
        {
          featureType: 'road.local',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#d7d7d7'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'geometry.fill',
          stylers: [
            {
              visibility: 'on'
            },
            {
              color: '#ebebeb'
            }
          ]
        },
        {
          featureType: 'administrative',
          elementType: 'geometry',
          stylers: [
            {
              color: '#a7a7a7'
            }
          ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#ffffff'
            }
          ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#ffffff'
            }
          ]
        },
        {
          featureType: 'landscape',
          elementType: 'geometry.fill',
          stylers: [
            {
              visibility: 'on'
            },
            {
              color: '#efefef'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#696969'
            }
          ]
        },
        {
          featureType: 'administrative',
          elementType: 'labels.text.fill',
          stylers: [
            {
              visibility: 'on'
            },
            {
              color: '#737373'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#d6d6d6'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {},
        {
          featureType: 'poi',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#dadada'
            }
          ]
        }
      ]
    })

    const allTabs = component.querySelectorAll('.js-contactMap__infoTab')
    const allContents = component.querySelectorAll('.js-contactMap__infoContent')

    // loadCumpelikova(map, mapIcon, component, allTabs, allContents)
    loadOlstynska(map, mapIcon, component, allTabs, allContents)
    // loadWaltariho(map, mapIcon, component, allTabs, allContents)
    loadCumpelikovaWaltarihoHybrid(map, mapIcon, component, allTabs, allContents)
  })
}
