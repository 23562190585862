/**
  * Sub-component - foodList_itemLists
  * Parent Component - foodList
 */

export const foodList_itemListsElements = (parentElement: HTMLElement) => {
  return parentElement.querySelectorAll('.js-foodList_itemLists__list') as unknown as HTMLElement[]
}

export const foodList_itemLists = (parentElement: HTMLElement, itemLists: HTMLElement[]): void => {
  if (itemLists === null) return

  const allShowMoreButtons = parentElement.querySelectorAll('.js-foodList_itemLists__showAllButton') as unknown as HTMLElement[]

  allShowMoreButtons.forEach((button: HTMLElement) => {
    button.addEventListener('click', () => {
      showAllSubLists(parentElement)
    })
  })
}

const showAllSubLists = (parentElement: HTMLElement) => {
  const allShowMoreButtonWrappers = parentElement.querySelectorAll('.js-foodList_itemLists__showAllButtonWrapper') as unknown as HTMLElement[]

  allShowMoreButtonWrappers.forEach((wrapper: HTMLElement) => {
    wrapper.remove()
  })

  const allSubLists = parentElement.querySelectorAll('.js-foodList_itemLists__listInner') as unknown as HTMLElement[]

  allSubLists.forEach((wrapper: HTMLElement) => {
    wrapper.classList.remove('-isHidden')
  })
}
