/**
 * Element - carouselGallery
 */
const engageCarouselGalleries = (): void => {
  const elementSelector = document.querySelectorAll('.js-carouselGallery') as unknown as HTMLElement[]
  elementSelector.forEach((component: HTMLElement) => {
    const galleryTriggers = component.querySelectorAll('.js-carouselGallery-single') as unknown as HTMLLinkElement[]
    const galleryTriggersList = [...galleryTriggers]

    window.addEventListener('load', () => {
      // Load the actual gallery
      /* @ts-ignore */
      const myGallery = GLightbox({
        /* @ts-ignore */
        elements: galleryTriggersList
      })

      galleryTriggers.forEach(trigger => {
        // Add a trigger to each miniature to load the gallery
        trigger.addEventListener('click', (e) => {
          e.preventDefault()

          // Determine what index we open the gallery on
          const index = galleryTriggersList.findIndex(single => {
            return (single.href === trigger.href)
          })

          // Open the gallery
          myGallery.openAt(index)
        })
      })
    })
  })
}
const carouselGalleryAnimator = (): void => {
  // Run the script for each individual element found on the page
  const elementSelector = document.querySelectorAll('.js-carouselGallery') as unknown as HTMLElement[]
  elementSelector.forEach((component: HTMLElement) => {
    const carouselGalleryWrapper = component.querySelector('.js-carouselGallery-wrapper') as unknown as HTMLElement
    const carouselGalleryInner = component.querySelector('.js-carouselGallery-inner') as unknown as HTMLElement

    const localStyleElement = component.querySelector('.js-style') as unknown as HTMLElement

    const localID = component.dataset.special_id

    const allLocalElems = component.querySelectorAll('.js-carouselGallery-single') as unknown as HTMLElement[]

    if (allLocalElems.length === 0) {
      return
    }

    let previousWidth = 0

    const checkCarouselSizing = () => {
      let localWidth = 0

      allLocalElems.forEach(icon => {
        const iconMarginLeft = parseInt(getComputedStyle(icon).marginLeft)
        const iconMarginRight = parseInt(getComputedStyle(icon).marginRight)

        localWidth += icon.offsetWidth + iconMarginLeft + iconMarginRight
      })

      if (window.innerWidth >= localWidth / 2) {
        carouselGalleryInner.classList.add('-maxSized')
      }
      else {
        carouselGalleryInner.classList.remove('-maxSized')
      }

      if (localWidth === previousWidth) {
        return
      }

      previousWidth = localWidth

      const carouselGalleryStyle = `
      @keyframes scroll-carouselGallery-${localID} {

        100% {
          transform: translateX(calc(-${(localWidth / 2)}px));
        }
      }
    `

      localStyleElement.innerHTML = carouselGalleryStyle

      carouselGalleryWrapper.style.width = `${localWidth / 2}px`
      carouselGalleryInner.style.width = `${localWidth}px`
      carouselGalleryInner.style.animation = `${localWidth / 2 / 100}s linear 0s infinite normal none running scroll-carouselGallery-${localID}`
    }

    // On resize
    window.addEventListener('resize', () => {
      checkCarouselSizing()
    })

    setInterval(() => {
      // checkCarouselSizing()
    }, 250)

    checkCarouselSizing()
  })
}

export const carouselGalleryManager = () => {
  engageCarouselGalleries()
  carouselGalleryAnimator()
}
