/**
 * Component - aboutUs
 */

export const aboutUs = (): void => {
  // Run the script for each individual component found on the page
  const componentSelector = document.querySelectorAll('.js-aboutUs') as unknown as HTMLElement[]
  componentSelector.forEach((component: HTMLElement) => {
    console.log(component)
  })
}
