/**
 * Layout - header
 */

export const headerScrollManager = (): void => {
  const headerShadowClass = '-has-shadow'
  const headerElement = document.querySelector('.js-header')
  addEventListener('scroll', () => {
    if (!headerElement) return

    if (window.scrollY === 0) {
      headerElement.classList.remove(headerShadowClass)
    }

    if (window.scrollY > 0) {
      headerElement.classList.add(headerShadowClass)
    }
  })
}

export const headerLinkManager = (): void => {
  const headerElement = document.querySelector('.js-header')
  const bodyElement = document.querySelector('.js-body') as unknown as HTMLElement

  if (headerElement) {
    const headerLinks = [
      ...headerElement.querySelectorAll('.js-headerMenu__menuItem'),
      headerElement.querySelector('.js-header__ctaButton')
    ] as unknown as HTMLElement[]

    headerLinks.forEach((link: HTMLElement) => {
      link.addEventListener('click', (e: Event) => {
        const url = link.getAttribute('href')

        if (!url) return

        const urlHash = url.split('#')[1]

        if (!urlHash) return

        const targetElement = document.querySelector(`#${urlHash}`)

        if (!targetElement) return

        e.preventDefault()
        bodyElement.classList.remove('-menuPopupPhone')

        const wpHeader = document.querySelector('#wpadminbar')

        const extraOffset = (wpHeader) ? wpHeader.clientHeight : 0

        // @ts-ignore
        const jQueryTargetElement = jQuery(targetElement)
        // @ts-ignore
        jQuery('html, body').animate({
          scrollTop: jQueryTargetElement.offset().top - extraOffset
        }, 1300)
      })
    })
  }
}

export const headerMobileMenuManager = () => {
  const bodyElement = document.querySelector('.js-body') as unknown as HTMLElement
  const headerElement = document.querySelector('.js-header') as unknown as HTMLElement
  const summonElement = document.querySelector('.js-header__control') as unknown as HTMLElement

  if (headerElement) {
    /* Closing via swipe */
    headerElement.addEventListener('swiped-left', function (e: any) {
      const startX: number = e.detail.xStart
      const endX: number = e.detail.xEnd

      if (endX - startX <= -100) {
        bodyElement.classList.remove('-menuPopupPhone')
      }
    })

    /* Closing via out-of-menu-bounds click */
    document.addEventListener('click', (e) => {
      const triggerElement = e.target as unknown as HTMLElement
      const menuElement = triggerElement.closest('.js-header__menuWrapper')

      if (!menuElement && summonElement !== triggerElement) {
        bodyElement.classList.remove('-menuPopupPhone')
      }
    })

    /* Opening via button click */
    summonElement.addEventListener('click', () => {
      bodyElement.classList.add('-menuPopupPhone')
    })

    /* Open via swipe */
    bodyElement.addEventListener('swiped-right', function (e: any) {
      const startX: number = e.detail.xStart
      const endX: number = e.detail.xEnd

      if (startX - endX <= -100) {
        bodyElement.classList.add('-menuPopupPhone')
      }
    })
  }
}

headerMobileMenuManager()
