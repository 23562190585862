/**
 * Endgages a single gallery
 */
export const engageSingleActivityGallery = (): void => {
  const gallery = document.querySelector('.js-singleActivity__gallery') as unknown as HTMLElement

  if (!gallery) return

  const galleryTriggers = gallery.querySelectorAll('.js-singleActivity__galleryImageWrapper') as unknown as HTMLLinkElement[]
  const galleryTriggersList = [...galleryTriggers]

  window.addEventListener('load', () => {
    // Load the actual gallery
    /* @ts-ignore */
    const myGallery = GLightbox({
      /* @ts-ignore */
      elements: galleryTriggersList
    })

    galleryTriggers.forEach(trigger => {
      // Add a trigger to each miniature to load the gallery
      trigger.addEventListener('click', (e) => {
        e.preventDefault()

        // Determine what index we open the gallery on
        const index = galleryTriggersList.findIndex(single => {
          return (single.href === trigger.href)
        })

        // Open the gallery
        myGallery.openAt(index)
      })
    })
  })
}
