/**
 * Component - weAreKnownFor
 */

// Sub-components

export const weAreKnownFor = ():void => {
  // Run the script for each individual component found on the page
  const componentSelector = document.querySelectorAll('.js-weAreKnownFor') as unknown as HTMLElement[]
  componentSelector.forEach((component:HTMLElement) => {
    console.log(component)
  })
}
