/**
 * Component - foodList
 */

// Sub-components
import { foodList_tabs, foodList_tabsElements } from './foodList/foodList_tabs'
import { foodList_itemLists, foodList_itemListsElements } from './foodList/foodList_itemLists'

export const foodList = (): void => {
  // Run the script for each individual component found on the page
  const componentSelector = document.querySelectorAll('.js-foodList') as unknown as HTMLElement[]
  componentSelector.forEach((component: HTMLElement) => {
    const tabsElements = foodList_tabsElements(component)
    const itemListsElements = foodList_itemListsElements(component)

    foodList_tabs(tabsElements, itemListsElements)
    foodList_itemLists(component, itemListsElements)
  })
}
